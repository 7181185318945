.hero-main {
  position: relative;
  width: 800px;
  height: 300px;
  overflow: hidden;
}
.herotext-cnt {
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 40px;
  

}
.herotext {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: bold;
  font-size: 40px;
  text-align: left;
  position: relative;
  margin-bottom: 10px;
  line-height: normal;
}
#hero1text {
  color: #FFFFFF;
}
#hero2text {
  color: #002DA5
}

#stoerer-blue {
  height: 600px;
  position: absolute;
  top: -150px;
  left: -200px;
  z-index: 20;
}

#stoerer-img {
  height: 400px;
  top: -50px;
  right: 40px;
  position: absolute;
  z-index: 20;
}

#hero1-bg-img{
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  transform: scale(1);
}

#hero2-bg-img{
  height: 300px;
}
